@import url("https://fonts.googleapis.com/css?family=Nanum+Gothic|Raleway|Libre+Baskerville&display=swap");
body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF; }
  @media screen and (max-width: 800px) {
    body {
      font-size: .8em; } }

.contact {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .contact form {
    position: relative;
    width: 50%; }
    @media only screen and (max-width: 800px) {
      .contact form {
        width: 90%; } }
    .contact form input, .contact form select {
      flex: 2; }
    .contact form select {
      margin: 5px 0; }
    .contact form label {
      margin-right: 10px; }
    .contact form button {
      float: right; }

.email_status {
  text-align: center;
  color: red;
  padding: 20px;
  font-size: 1.5em; }

.nav {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #505050; }
  .nav .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap; }
  .nav .title {
    color: white;
    font-size: 2em;
    padding-left: 10px; }
  .nav .subtitle {
    color: white;
    font-size: 1.2em;
    margin-left: 10px; }
    @media screen and (max-width: 450px) {
      .nav .subtitle {
        display: none; } }
  .nav ul {
    display: inline;
    list-style-type: none;
    font-size: 1.2em;
    color: white; }
    .nav ul li {
      margin-top: 20px;
      padding: 10px;
      display: inline; }
      .nav ul li a {
        color: white;
        text-decoration: none; }
  .nav .nav-head {
    color: white;
    font-size: 1.2em; }
  .nav form {
    margin-right: 20px; }
  .nav input.login {
    border: unset;
    width: 75px;
    height: unset;
    padding: 5px;
    margin-left: 10px; }

.nav-icons {
  display: flex;
  justify-content: space-between;
  align-items: baseline; }
  .nav-icons .icon {
    font-size: 1.5em;
    padding: 0 5px; }
    @media screen and (max-width: 800px) {
      .nav-icons .icon {
        font-size: 2em; }
        .nav-icons .icon.home, .nav-icons .icon.edit {
          display: none; }
        .nav-icons .icon.menu {
          padding: 0; } }

.menu, .home, a.home {
  color: white;
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  border: none; }

.menu:focus .sub-menu,
.menu:focus-within .sub-menu,
.menu:hover .dropdown_menu {
  visibility: visible;
  /* shows sub-menu */
  opacity: 1;
  z-index: 1;
  transition-delay: 0s, 0s, 0.3s; }

.dropdown_menu {
  visibility: hidden;
  /* hides sub-menu */
  display: flex;
  opacity: 0;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  z-index: -1;
  position: absolute;
  top: 50px;
  right: 0;
  flex-direction: column;
  position: fixed;
  background-color: darkorchid;
  color: white; }

.menu_item, a.menu_item {
  color: white;
  font-size: 1em;
  text-decoration: none;
  padding: 10px 20px;
  white-space: nowrap; }
  @media screen and (max-width: 800px) {
    .menu_item, a.menu_item {
      font-size: 1.4em; } }
  .menu_item:first-child, a.menu_item:first-child {
    padding-top: 15px; }
  .menu_item:last-child, a.menu_item:last-child {
    padding-bottom: 15px; }
  .menu_item:hover, a.menu_item:hover {
    background-color: white;
    color: darkorchid; }

.footer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 30px;
  text-align: center;
  color: white;
  background-color: black;
  font-size: 1em; }

.welcome {
  position: relative;
  color: white;
  padding: 3px;
  font-size: 1.2em;
  overflow: hidden;
  margin: 0 5px;
  flex: 1 1 100%; }

.marquee {
  display: block;
  width: 100%;
  position: relative;
  animation: scroll 20s linear infinite;
  padding: 3px;
  white-space: nowrap; }

@keyframes scroll {
  0% {
    left: 100vw; }
  100% {
    left: -100vw; } }

.loginPage {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-bottom: 100px; }
  .loginPage .login-form {
    background-color: white; }
    @media only screen and (max-width: 800px) {
      .loginPage .login-form p, .loginPage .login-form h2 {
        padding: 0 20px; } }
  .loginPage form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; }
    @media only screen and (max-width: 800px) {
      .loginPage form {
        padding: 0 20px; } }
    .loginPage form input {
      margin: 0; }
  .loginPage .fill {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .loginPage .fill .runin {
      min-width: unset; }
  .loginPage .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px; }
    .loginPage .links > * {
      margin: 10px 0 0;
      color: indigo; }
      .loginPage .links > *:hover {
        color: darkorchid; }

.register_container {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .register_container .register {
    width: 50%; }
    .register_container .register p {
      line-height: 1.8em; }
    @media only screen and (max-width: 800px) {
      .register_container .register {
        width: 90%; } }
    .register_container .register form {
      margin: 25px;
      margin-bottom: 150px; }
      .register_container .register form .rbut {
        margin: 0 5px 0 15px; }
      .register_container .register form .reg {
        margin-top: 15px; }
        .register_container .register form .reg input {
          width: 100%;
          max-width: 100%; }
      .register_container .register form .note {
        font-size: .8em; }
      .register_container .register form .subbutt {
        margin: 15px 0; }

.main-page.main-column.search {
  margin-top: 50px; }

.search-label {
  color: indigo;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0; }

.searchContent.main-column {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 70px; }
  .searchContent.main-column.hide {
    display: none; }
  .searchContent.main-column:last-child {
    padding-bottom: 100px; }
  .searchContent.main-column form {
    width: 100%;
    padding-top: 20px; }
    .searchContent.main-column form:first-child {
      padding-top: 0; }
    @media screen and (max-width: 600px) {
      .searchContent.main-column form {
        width: 95%; } }
  .searchContent.main-column select[id=state] {
    margin: 0; }
  .searchContent.main-column input[name=show], .searchContent.main-column input[name=theater] {
    box-sizing: border-box;
    max-width: unset;
    width: 100%;
    padding: 5px;
    color: black;
    background-color: #EEE;
    border-radius: 5px; }
  .searchContent.main-column input[type='text'] {
    background-color: #EEE; }

.searchContent {
  width: 100%; }
  .searchContent .searchResults {
    width: 100%; }

.search_result {
  width: 100%;
  padding: 25px 0;
  font-size: 1.2em;
  text-align: left;
  background-color: #e7e1f1; }
  .search_result:nth-child(even) {
    background-color: #F5F5F5; }
  .search_result .number_long {
    font-size: 2em;
    font-weight: bold;
    min-width: 75px;
    color: indigo;
    text-align: center; }
  .search_result .theater_address {
    margin-left: 65px; }

.upcoming {
  margin-top: 15px;
  font-size: .9em; }
  .upcoming .runin {
    font-size: .9em; }
  .upcoming .prod {
    font-weight: bold;
    font-style: italic;
    font-size: .9em; }

.next {
  font-size: .8em;
  font-weight: bold;
  color: indigo;
  text-transform: uppercase; }

.prod {
  font-size: 1.2em;
  color: darkorchid; }

.noresults {
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  padding: 50px 0;
  background-color: #e7e1f1; }

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white; }
  .pagination.main-column {
    padding-top: 25px;
    padding-bottom: 100px; }
  .pagination .subbutt {
    cursor: pointer;
    font-size: 1em;
    padding: 5px 10px; }
    .pagination .subbutt.prev {
      margin: 0 20px 0 0; }
    .pagination .subbutt.next {
      margin: 0 0 0 20px; }

.show_results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .show_results .title {
    font-weight: bold;
    font-size: 1.2em; }
  .show_results .toggle {
    padding: 0; }

.upcoming div {
  display: block; }

.theater_name {
  display: flex;
  align-items: flex-start;
  flex-direction: column; }

.result-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  padding: 20px 0;
  flex-wrap: nowrap;
  margin: 0 10px; }
  .result-item div:not(:first-child) {
    margin-left: 70px; }
    @media screen and (max-width: 600px) {
      .result-item div:not(:first-child) {
        margin-left: 55px; } }
  .result-item .link-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .result-item .link-group a div {
      margin-left: 15px;
      display: inline-block; }

.show-title {
  background-color: white; }
  .show-title div {
    padding: 25px 0;
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    color: indigo; }

.main-column {
  width: 50%;
  min-width: 750px;
  max-width: 750px;
  margin: 0 50px; }
  @media screen and (max-width: 800px) {
    .main-column {
      width: 100%;
      min-width: 100%;
      max-width: 100%; } }

.basic_link {
  color: indigo; }

.admin-theater {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px; }
  .admin-theater > form {
    width: 50%; }

h2.main-page {
  margin: 20px auto;
  padding: 20px 0;
  font-family: 'Libre Baskerville', serif;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  background-color: indigo;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0; }
  h2.main-page.gray {
    background-color: darkgrey; }
  h2.main-page.adm {
    margin-top: 25px; }
    @media screen and (max-width: 800px) {
      h2.main-page.adm {
        margin-top: 35px; } }
  h2.main-page.showlast {
    order: 9; }

h3.sub-column {
  margin: 0 auto;
  font-family: 'Libre Baskerville', serif;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  background-color: darkgray;
  text-align: center;
  padding: 10px; }

.artist-name {
  display: inline-block; }
  .artist-name div {
    display: inline-block; }
  .artist-name div:first-child {
    margin-right: 5px; }
  .artist-name div:last-child {
    margin-right: 5px; }

.theater {
  background-color: white; }
  .theater .description {
    border-left: 2px solid darkorchid;
    border-right: 2px solid darkorchid;
    padding: 20px; }

.theaters {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0 200px; }
  .theaters .theater_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px; }
    .theaters .theater_info > * {
      font-size: 1.4em; }
    .theaters .theater_info .theater_name {
      font-family: 'Libre Baskerville', serif;
      font-size: 2em;
      color: indigo; }
    .theaters .theater_info .specialty {
      width: 75%;
      color: indigo;
      background-color: #e7e1f1;
      border: 1px solid indigo;
      font-size: 1.5em;
      padding: 10px 0;
      margin: 40px 0;
      text-align: center; }
  .theaters .theater-info-table {
    width: 100%;
    padding: 15px; }
  .theaters .theater-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .theaters .table-button {
    padding: 0 10px;
    cursor: pointer; }
    .theaters .table-button .table-edit {
      font-size: 2em; }
    .theaters .table-button .table-submit {
      font-size: 2em;
      color: indigo;
      margin-right: 10px; }
    .theaters .table-button .table-return {
      font-size: 2em;
      color: crimson; }
  .theaters .table-label {
    font-weight: bold;
    color: indigo;
    text-align: right; }
  .theaters .table-field input {
    width: 100%; }

.showlast ~ .productions .production:last-child {
  border-bottom: unset; }

.toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 750px; }
  @media screen and (max-width: 800px) {
    .toggle {
      width: 100%;
      min-width: unset; } }
  .toggle .toggle-button {
    cursor: pointer;
    width: 50%;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    background-color: darkgray;
    padding: 15px 0; }
    .toggle .toggle-button:hover {
      background-color: darkorchid;
      color: white; }
      .toggle .toggle-button:hover.active {
        color: white; }
    .toggle .toggle-button.active {
      color: indigo; }
    .toggle .toggle-button:first-child {
      border-right: 2px solid white; }

.productions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white; }
  .productions .empty {
    width: 100%;
    text-align: center;
    padding: 100px 0;
    background-color: #e7e1f1; }
  .productions.hide {
    display: none; }
  .productions .production {
    position: relative;
    padding-top: 40px;
    width: 100%;
    border-bottom: 2px solid indigo;
    background-color: #F5F5F5; }
    .productions .production:nth-child(odd) {
      background-color: #e7e1f1; }
    .productions .production .favorite {
      font-size: 1.6em;
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer; }
      .productions .production .favorite.false:after {
        content: '\2661';
        color: gray; }
      .productions .production .favorite.false:hover, .productions .production .favorite.false:active {
        color: pink; }
      .productions .production .favorite.true:after {
        content: '\2665';
        color: red; }
      .productions .production .favorite.true:hover, .productions .production .favorite.true:active {
        color: lightgray; }
      .productions .production .favorite .login_message {
        position: absolute;
        display: none;
        width: 200px;
        height: 80px;
        top: -110px;
        right: 0;
        font-size: 14px;
        color: black;
        background-color: white;
        padding: 15px;
        border: indigo 1px solid;
        box-shadow: 3px 3px 3px lightgray; }
      .productions .production .favorite.noreg:hover .login_message, .productions .production .favorite.noreg:active .login_message {
        display: block; }
    .productions .production .details-table {
      margin: 50px auto 30px;
      padding: 0 20px; }
      .productions .production .details-table tr {
        vertical-align: top; }
        .productions .production .details-table tr td {
          padding: 0 10px 10px 0; }
          .productions .production .details-table tr td:first-child {
            color: indigo;
            font-weight: bold;
            margin-right: 100px;
            text-align: right; }
  .productions .show_dates {
    font-family: 'Libre Baskerville', serif;
    font-style: italic;
    font-weight: bold;
    font-size: 1.1em;
    color: #242B40;
    margin: 10px;
    text-align: center; }
  .productions .show_title {
    font-family: 'Libre Baskerville', serif;
    margin-bottom: 15px;
    font-size: 2em;
    font-weight: 700;
    color: indigo;
    padding: 10px;
    text-align: center; }
  .productions .genre-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px; }
  .productions .genre {
    background-color: lightgray;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 5px 8px;
    margin: 3px 3px 3px 0;
    width: 300px;
    text-align: center; }
  .productions .venue {
    margin: 10px; }
  .productions .description, .productions .cast {
    border-top: 1px solid indigo;
    border-top: 1px solid indigo;
    margin: 0 20px 30px;
    padding-top: 10px;
    line-height: 2em; }
    .productions .description p, .productions .cast p {
      margin-block-start: .5em;
      margin-block-end: .5em;
      margin-inline-start: .5px;
      margin-inline-end: .5px; }
    .productions .description .title, .productions .cast .title {
      font-family: "Raleway", sans-serif;
      font-size: 1.2em;
      font-weight: bold;
      color: indigo;
      margin-top: 20px; }

.instr {
  font-weight: 600;
  color: indigo;
  margin: 20px 0; }

.edit-prod-buttons {
  display: flex;
  justify-content: space-around;
  margin: 30px 0 10px;
  padding: 0 20%; }
  .edit-prod-buttons .form-button-3 {
    width: 50%;
    background-color: indigo;
    color: white;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    padding: 15px;
    cursor: pointer;
    white-space: nowrap; }
    .edit-prod-buttons .form-button-3:hover {
      background-color: gray; }
    .edit-prod-buttons .form-button-3:first-child {
      border-right: 2px solid white; }

.add_artist {
  width: 100%;
  height: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out; }
  .add_artist.show {
    display: block;
    height: auto;
    max-height: 100%; }

.expand {
  cursor: pointer; }
  .expand .plus {
    padding-right: 10px;
    font-size: 1.5em; }

.toolbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 50px;
  width: 100%;
  min-width: 200px;
  background-color: indigo;
  height: 25px;
  border-bottom: 2px solid white;
  z-index: 50; }
  @media screen and (max-width: 600px) {
    .toolbar {
      height: 35px; } }
  .toolbar h2 {
    font-family: 'Libre Baskerville', serif;
    color: crimson;
    text-weight: bold;
    text-shadow: .25px .25px 8px white; }
  .toolbar .head {
    text-align: center;
    line-height: 2.2em;
    background-color: black;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    margin: 0;
    color: white;
    font-weight: 900;
    font-size: .8em; }
  .toolbar .tool {
    box-sizing: border-box;
    text-align: center;
    padding: 5px;
    width: 100%;
    color: white;
    cursor: pointer;
    font-weight: bold;
    font-size: .8em; }
    .toolbar .tool:hover {
      border: 1px solid indigo;
      background-color: #505050; }
    @media screen and (max-width: 600px) {
      .toolbar .tool {
        border-left: 1px solid white; } }

.aboutus {
  margin: 75px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .aboutus .aboutus_column {
    width: 50%; }
    .aboutus .aboutus_column h1 {
      text-align: center;
      margin-bottom: 30px; }
    .aboutus .aboutus_column p {
      margin-bottom: 30px;
      line-height: 2em; }
    .aboutus .aboutus_column li {
      margin: 0 0 1em; }
    @media only screen and (max-width: 800px) {
      .aboutus .aboutus_column {
        width: 80%;
        font-size: 1.2em; } }
  .aboutus .mytheaters {
    width: 1px;
    height: 1px;
    overflow: hidden; }

.overlay-container {
  position: relative;
  width: 40%;
  padding: 30px;
  background-color: darkorchid;
  border-radius: 10px;
  margin-bottom: 50px; }
  @media only screen and (max-width: 600px) {
    .overlay-container {
      width: 80%; } }
  .overlay-container .close {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 0;
    color: white;
    font-size: 3em;
    margin-top: auto; }
    .overlay-container .close:hover {
      color: indigo; }
  .overlay-container .error {
    width: 100%;
    padding: 10px 0;
    margin-bottom: 15px; }

.note {
  margin: 20px;
  font-weight: bold;
  text-align: center;
  font-size: 1.5em; }

.form-group {
  width: 100%; }

.form-title {
  color: white;
  margin-top: 0; }

h2.form-title {
  text-transform: uppercase; }

h3.form-title {
  width: 100%;
  color: white;
  font-weight: 800;
  margin-top: 25px;
  border-top: 2px solid indigo;
  padding-top: 5px; }

h4.form-title {
  width: 100%;
  color: white;
  font-weight: 800;
  margin-top: 10px;
  border-top: 2px solid indigo;
  margin-block-start: 5px;
  margin-block-end: 5px; }

.form-group {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px; }
  .form-group.subform {
    background-color: #e7e1f1;
    padding: 10px 2px;
    border-radius: 5px; }
    .form-group.subform .label {
      color: indigo; }
  .form-group.radio {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start; }
    .form-group.radio .radio-group {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 30px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      /* Hide the browser's default radio button */
      /* Create a custom radio button */
      /* When the radio button is checked, add a  background */ }
      .form-group.radio .radio-group:hover input ~ .custom-radio {
        background-color: darkgray; }
      .form-group.radio .radio-group .radio-label {
        margin-left: 25px; }
      .form-group.radio .radio-group input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        z-index: 5; }
      .form-group.radio .radio-group .custom-radio {
        position: absolute;
        top: 5px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: lightgray;
        border-radius: 50%;
        z-index: 4; }
      .form-group.radio .radio-group input:checked ~ .custom-radio {
        background-color: #FFFFFF;
        border: 6px solid indigo;
        box-sizing: border-box; }
  .form-group.form-group.datetime .date-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .form-group.form-group.datetime .date-info div:first-child {
      margin-right: 20px; }
  .form-group.form-group.datetime .react-time-picker {
    height: 35px;
    max-height: 35px;
    cursor: pointer; }
    .form-group.form-group.datetime .react-time-picker > * {
      background-color: white;
      padding: 0 5px; }
  .form-group.form-group.datetime .react-time-picker__wrapper {
    border-radius: 5px; }
  .form-group.form-group.datetime .react-time-picker__clear-button {
    display: none; }
  .form-group.form-group.datetime .react-time-picker__inputGroup__amPm {
    margin-right: 0; }
  .form-group.form-group.datetime .timezone-picker {
    width: 300px;
    min-width: 150px;
    max-width: 100%; }
    .form-group.form-group.datetime .timezone-picker input {
      border-radius: 5px;
      padding: 8px;
      font-size: 1.1em;
      font-family: 'Raleway', sans-serif;
      cursor: pointer; }
    .form-group.form-group.datetime .timezone-picker ul {
      background-color: white; }
  .form-group.form-group.datetime .time {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
    .form-group.form-group.datetime .time > .form-select.wide {
      min-width: unset;
      font-size: 14px; }
  .form-group .label {
    color: white;
    font-weight: bold;
    white-space: nowrap;
    margin: 10px 0 5px; }
  .form-group .form-select.wide {
    width: 100%;
    min-width: 100%; }
  .form-group input[type='text'], .form-group input[type='password'], .form-group input[type='email'] {
    box-sizing: border-box;
    max-width: unset;
    width: 100%;
    padding: 5px;
    color: black;
    background-color: #EEE;
    border-radius: 5px; }
  .form-group select {
    color: black;
    background-color: #EEE; }
  .form-group textarea {
    color: black;
    background-color: #EEE;
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    border-color: gray; }

.form-group.date_search, .form-group.event_search {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center; }
  .form-group.date_search .label, .form-group.event_search .label {
    color: indigo;
    margin-right: 15px; }
  .form-group.date_search select, .form-group.event_search select {
    width: 100%;
    padding: 5px;
    border-radius: 10px; }

.form-button {
  width: 100%;
  font-size: 1.2em;
  color: white;
  font-weight: 600;
  background-color: indigo;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer; }
  .form-button.edit {
    background-color: darkgray; }

.form-button-2 {
  font-size: 1em;
  color: white;
  background-color: indigo;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid white;
  margin: 0 0 0 5px;
  text-align: center; }

.subform-wrapper {
  height: 100%;
  padding-left: 30px; }

.another-artist {
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1em;
  color: white;
  font-weight: bold;
  background-color: darkgray;
  border-radius: 10px;
  padding: 0 20px;
  cursor: pointer;
  border: 1px solid white;
  margin: 0 0 20px 30px; }
  .another-artist .plus {
    font-size: 2em;
    margin-right: 10px; }

.edit_buttons {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end; }

.creatives {
  margin-top: 20px; }

input[type='text'], input[type='email'], input[type='password'] {
  margin: 5px 0;
  font-size: 1.2em;
  font-family: 'Raleway', sans-serif;
  max-width: 75%;
  padding: 5px;
  border: none;
  border-bottom: 2px solid gray;
  color: indigo; }
  input[type='text'].person, input[type='email'].person, input[type='password'].person {
    width: 100px; }

.loginPage form .optin, .datetime .optin, .free .optin {
  position: relative;
  margin: 15px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Create a custom checkbox */
  /* When the radio button is checked, add a  background */ }
  .loginPage form .optin input, .datetime .optin input, .free .optin input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 5; }
  .loginPage form .optin .agree, .datetime .optin .agree, .free .optin .agree {
    font-size: .8rem;
    margin-left: 25px; }
  .loginPage form .optin .label, .datetime .optin .label, .free .optin .label {
    color: white;
    font-weight: bold;
    white-space: nowrap;
    margin: 10px 0 5px;
    margin-left: 25px;
    line-height: 10px; }
  .loginPage form .optin:hover input ~ .custom-checkbox, .datetime .optin:hover input ~ .custom-checkbox, .free .optin:hover input ~ .custom-checkbox {
    background-color: darkgray; }
  .loginPage form .optin .custom-checkbox, .datetime .optin .custom-checkbox, .free .optin .custom-checkbox {
    position: absolute;
    top: 5px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: lightgray;
    border-radius: 50%;
    z-index: 4; }
  .loginPage form .optin input:checked ~ .custom-checkbox, .datetime .optin input:checked ~ .custom-checkbox, .free .optin input:checked ~ .custom-checkbox {
    background-color: #FFFFFF;
    border: 6px solid indigo;
    box-sizing: border-box; }

.venues {
  background-color: white; }
  .venues.showlast {
    order: 10;
    padding-bottom: 50px; }
    .venues.showlast .venue:last-child {
      border-bottom: 2px solid indigo; }
  .venues .venue {
    border-bottom: 2px solid indigo;
    display: grid;
    grid-template-columns: 20% 40% 20% 20%;
    align-items: center;
    padding: 30px; }
    .venues .venue:last-child {
      border-bottom: none; }

.number {
  text-align: center;
  background-color: indigo;
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  font-size: 2em;
  color: white;
  border-radius: 50%;
  line-height: 1.4em; }
  @media screen and (max-width: 800px) {
    .number {
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      line-height: 1.1em;
      font-size: 1.8em;
      margin-left: 10px; } }

.article .subttl {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px; }

.article .show_item {
  margin-left: 50px;
  margin-bottom: 20px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.MainBody {
  position: relative;
  width: 100%; }

.head1 {
  margin: 40px 0 20px;
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase; }

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px; }
  .body .main {
    position: relative;
    top: 50px;
    width: 100vw;
    min-width: 360px;
    height: 400px; }
    @media screen and (max-width: 1070px) {
      .body .main {
        height: 250px; } }
    .body .main .intro {
      position: absolute;
      width: 70%;
      min-width: 360px;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .body .main .intro > * {
        font-size: 1.2em;
        line-height: 1.5em;
        color: white; }
      .body .main .intro .content {
        padding: 10%; }
      @media screen and (max-width: 800px) {
        .body .main .intro {
          font-size: .8em; } }
      @media screen and (max-width: 600px) {
        .body .main .intro {
          width: 100vw;
          height: 100%;
          top: 0;
          right: unset;
          left: 0;
          background-color: rgba(0, 0, 0, 0.2); }
          .body .main .intro .content {
            padding: 20px; } }

.group {
  margin: 25px 0; }

.instruction {
  font-weight: 700;
  margin: 20px 0 10px;
  font-size: 1.2em; }

.add-div {
  padding: 2px;
  margin: 0  150px 30px;
  text-align: center;
  font-size: 1em;
  color: white;
  background-color: darkgray;
  height: 1.3em;
  width: 10em;
  border: gray solid 1px;
  border-radius: 5px;
  cursor: pointer; }

.artist_item {
  margin: 20px; }

.add_a_show, .add_venue {
  padding: 20px;
  background-color: darkorchid;
  margin: 20px 0; }

select {
  margin-right: 20px; }

.theater_item {
  padding: 5px; }

.stage_rabbit {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: ivory; }

.submit_me {
  cursor: pointer;
  padding: 2px;
  text-align: center;
  color: white;
  font-weight: 600;
  background-color: indigo;
  border-radius: 5px;
  width: 50px;
  height: 15px; }

.subbutt {
  margin-left: 20px;
  font-size: 1.2em;
  color: white;
  font-weight: 600;
  background-color: indigo;
  border-radius: 5px; }

.addbutt {
  width: 50px;
  margin: 0 20px;
  color: white;
  font-weight: 600;
  background-color: darkgray;
  border-radius: 5px;
  margin-bottom: 20px; }

.all_shows, .add_venue {
  padding: 50px; }

.all_shows {
  background-color: darkorchid; }

.add_show {
  padding: 50px;
  background-color: darkorchid; }

.clickable {
  cursor: pointer;
  color: #e8d25a; }
  .clickable:hover {
    color: white; }

div.list.clickable {
  width: 90px;
  text-decoration: none;
  border-radius: 5px;
  color: white;
  font-style: unset;
  border-right: 2px white solid; }

.list {
  color: #e8d25a;
  font-size: 1em;
  font-weight: bold;
  margin-left: 30px; }

.runin {
  color: indigo;
  font-size: 1em;
  font-weight: bold;
  min-width: 200px;
  margin-right: 10px;
  font-style: italic; }

textarea {
  margin: 5px 0;
  width: 90%;
  height: 100px;
  font-size: 1em;
  font-family: 'Raleway', sans-serif;
  padding: 10px; }

a {
  color: white;
  font-size: 1.2em;
  text-decoration: none !important;
  padding-bottom: 2px; }
  a:link, a:visit, a:active {
    color: white;
    text-decoration: none; }
  a:hover {
    cursor: pointer;
    color: darkorchid; }
  a.link {
    margin-left: 10px; }
  a.inline {
    color: darkorchid; }

.website {
  text-decoration: none;
  color: indigo;
  cursor: pointer;
  font-weight: bold;
  border: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto; }
  .website:hover {
    color: darkorchid; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  z-index: 1000000000;
  padding-top: 20px; }
  .overlay.hide {
    display: none; }

.add_show, .all_shows, .add_venue {
  position: relative;
  max-width: 33%; }

select {
  max-width: 75%;
  font-size: 1.2em; }

.edit_tools {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  padding: 10px;
  background-color: #242B40;
  border-radius: 0 0 10px 10px; }
  .edit_tools.inter {
    border-radius: 10px; }

.error {
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  padding: 5px;
  background-color: red;
  margin: 5px 0;
  width: 50%;
  border-radius: 5px; }
  .error h4 {
    text-align: center; }

.email_item {
  margin: 15px; }
  .email_item a {
    margin: 0 15px; }
  .email_item input {
    margin-left: 62px;
    height: 25px;
    width: 300px;
    font-size: 1em; }

.all_emails {
  margin: 75px 100px 100px; }
  .all_emails .email_item {
    margin: 50px 0; }
    .all_emails .email_item > div {
      margin: 10px 0; }

.trash, .update {
  cursor: pointer;
  font-size: 1.2em; }
